
.modal__backdropp {
	background: rgba(0, 0, 0, 0.974);
	bottom: 0;
	left: 0;
	overflow: auto ;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modal__container {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 560px;
}


button {
	background: #8c54ff;
	border: 0;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;
	transition: transform 100ms ease-out;
	
}

