@import  "~bootstrap/dist/css/bootstrap.css";

/* body{
width: 100%;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=search]{
  text-align: center;
  font: inherit;
  border: 6px solid #a3d8d6;
  padding: 13px 12px;
  font-size: 15px;
  box-shadow: 0 1px 1px #DDD;
  width: 384px;
  outline: none;
  display: block;
  color: #7B8585;
  margin: 0 auto 20px;
}
input:invalid ~ span::after {
  content: "✖";
  padding-left: 5px;
  position: absolute;
}

input[type=email] {
  text-align: center;
  font: inherit;
  border: 6px solid #a3d8d6;
  padding: 13px 12px;
  font-size: 15px;
  box-shadow: 0 1px 1px #DDD;
  width: 384px;
  outline: none;
  display: block;
  color: #7B8585;
  margin: 0 auto 20px;
}

input[type=password] {
  text-align: center;
  font: inherit;
  border: 6px solid #a3d8d6;
  padding: 13px 12px;
  font-size: 15px;
  box-shadow: 0 1px 1px #DDD;
  width: 384px;
  outline: none;
  display: block;
  color: #7B8585;
  margin: 0 auto 20px;
}



input[type=number], select {
  width: 10%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing:border-box;
}

.post{
max-height: 100% !important;
max-width: 100%;


}

.textarea {
  width: 100% !important;
  height: 150px !important;
  padding: 12px 20px !important;
  box-sizing: border-box !important;
  border: 2px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #f8f8f8;
  font-size: 16px !important;
  resize: none !important;
}
/* input[type=number] {
  padding:10px;
  border-radius: 10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
} */


/* CSS */
.button-19 {
  appearance: button;
  background-color: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 10%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #1CB0F6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}


.post-container {
  background: #e2e8d5;
  display: flex;
  flex-direction: column;
  padding: 3%;
  margin: 0 2%;
}
.heading {
  height: 126px;
  text-align: center;
  display: flex;
  align-items: center;
}
.image {
  width: 100%;
  height: 210px;
}

.posts-container {
  background-color: #f4e80c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img{
  width:20%;
  height:30%;
  border-radius: 50%;
  align-items: center;


  }





  
  /* CSS */
  .button-83 {
    appearance: button;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #fff, #f8eedb);
    border: 0 solid #e5e7eb;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #482307;
    column-gap: 1rem;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 100%;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    outline: 2px solid transparent;
    padding: 1rem 1.5rem;
    text-align: center;
    text-transform: none;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81,41,10,0.1),0px 2px 2px rgba(81,41,10,0.2);
  }
  
  .button-83:active {
    background-color: #f3f4f6;
    box-shadow: -1px 2px 5px rgba(81,41,10,0.15),0px 1px 1px rgba(81,41,10,0.15);
    transform: translateY(0.125rem);
  }
  
  .button-83:focus {
    box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 8px 10px rgba(81,41,10,0.1), 0px 2px 2px rgba(81,41,10,0.2);
  }

  #infoContainer {
    padding-left: 15px;
    padding-bottom: 40px;
    width: 10%;
    height: 10%;
  }



    
    /* table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
    }
    
    th {
    border-bottom: 1px solid black;
    }
    
    td {
    text-align: center;
    }
     */

     #employee {
      border-collapse: collapse;
      border: 3px solid #ddd;
    }
    
    #employee td,
    #employee th {
      border: 1px solid #ddd;
      padding: 12px;
    }
    
    #employee tr:hover {
      background-color: #ddd;
    }
    
    #employee th {
      padding: 10px;
      text-align: center;
      background-color: #4caf50;
      color: white;
    }




.button-20 {
  appearance: button;
  background-color: #4D4AE8;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  border: 1px solid #4D4AE8;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: .5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-20:focus:not(:focus-visible),
.button-20:focus {
  outline: 0;
}

.button-20:hover {
  background-color: #3733E5;
  border-color: #3733E5;
}

.button-20:focus {
  background-color: #413FC5;
  border-color: #3E3BBA;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset, rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:active {
  background-color: #3E3BBA;
  background-image: none;
  border-color: #3A38AE;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.button-20:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:disabled {
  background-image: none;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}